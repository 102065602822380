class ZIDXSavedSearchView implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxSavedSearchViewContainer";
    }
    getMatchURL(){
        return "/view-saved-search/";
    }
    render(){
        // console.log("view saved search");
    }
}